import { createSlice } from "@reduxjs/toolkit"
import { getConversations } from "./crispConversation.thunk"

export const CrispConversationSlice = createSlice({
  name: "crisp_conversations",
  initialState: {
    success: false,
    message: "",
    conversations: [],
    params: {},
    effect_loading: {
      block_all: false
    }
  },
  reducers: {
    setConversations: (state, action) => {
      state.conversations = action.payload
    },
    setParams: (state, action) => {
      state.params = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConversations.fulfilled, (state, action) => {
        state.effect_loading.block_all = false
        state.conversations = action.payload.data.data?.items
      })
      .addCase(getConversations.pending, (state) => {
        state.effect_loading.block_all = true
      })
      .addCase(getConversations.rejected, (state) => {
        state.effect_loading.block_all = false
      })
  }
})
export const { setConversations, setParams } = CrispConversationSlice.actions

export default CrispConversationSlice.reducer
