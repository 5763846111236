import { createAsyncThunk } from "@reduxjs/toolkit"
import { request } from "../../../utility/toolRequest"

const api = `${process.env.REACT_APP_API_URL}/api/tools`
const crispApi = `${api}/crisp/analyze/conversation-joined`

export const getConversations = createAsyncThunk("crisp/getConversations", async (params) => {
  const response = await request.get(`${crispApi}`, { params })
  return response
})
